Vue.use(VueDataScooper);
$(document).ready(function() {
    if(window.matchMedia('(min-width: 910px)').matches) {
        var stickyTop = $('#navbar-fixed-top').offset().top;
        $(window).scroll(function() {
            var windowTop = $(window).scrollTop();
           // console.log(windowTop,' ',stickyTop);
            if (stickyTop < windowTop) {
                $('#navbar-fixed-top').css({'position': 'fixed', 'z-index': '1', 'top': '0', 'box-shadow': '0px 10px 10px 0px rgba(97, 97, 97, 0.17)'});

            } else {
                $('#navbar-fixed-top').css({'position': 'relative', 'z-index': '0'});
            }

        });
    }
});

$.fn.hasExtension = function (exts) {
	let regexp = "(.*?)\\.("
			+ exts.join('|')
			+ ")$";
	// console.log('hasExtension', regexp, (new RegExp(regexp)).test($(this).val().toLowerCase()), this, $(this).val());
	return (
			new RegExp(regexp)
	).test($(this).val().toLowerCase());
};

let App = {

	func: {

		reloadEl: el => {
			console.log('el', el)
			if (el.hasA('ref-after-reload')) {
				let afterReload = el.A('ref-after-reload');
				return el.reloadIt(false)
					.then(function () {
						eval(afterReload);
					});
			} else {
				return el.reloadIt(false);
			}
		},

		//Календарь

		reloadDatePicker: () => {
			$(function () {
				var bindDatePicker = function () {
					$('.date').datetimepicker({
						useCurrent: false,
						format: 'DD.MM.YYYY',
						locale: 'ru',
						icons: {
							time: 'fa fa-clock-o',
							date: 'fa fa-calendar',
							up: 'fa fa-arrow-up',
							down: 'fa fa-arrow-down'
						}
					})
					.on('dp.change',function(e){
					//	console.log('e', e);
						if (!e.date) {
							$(this).val();
							return;
						}
						var date = parseDate(e.date.format('DD.MM.YYYY'));

						let input = $(this).find('input').get(0);
					//	console.log('date', input);
						if (input.hasA('on-change')) {
							let func = input.A('on-change');
							new Function("date", func).call(this, date);
						}

						// $(this).find('input').get(0).hasA('test') = date;
						$(this).val(date);
						if (input.hasA('name')) {
							artTable.changeFilter(input, 'refListData');
						}
					});
					$('.calendar-mask').inputmask(
						{ alias: 'dd.mm.yyyy', placeholder: 'дд.мм.гггг', showMaskOnHover: false }
						// { alias: 'yyyy-mm-dd', placeholder: 'гггг-мм-дд', showMaskOnHover: false }
					)
				};

				var isValidDate = function (value, format) {
					format = format || false;
					if (format) {
						value = parseDate(value);
					}

					var timestamp = Date.parse(value);

					return isNaN(timestamp) == false;
				};

				var parseDate = function (value) {
					var m = value.match(/^(\d{1,2})(\/|-)?(\d{1,2})(\/|-)?(\d{4})$/);
					if (m)
						value = m[5] + '-' + ('00' + m[3]).slice(-2) + '-' + ('00' + m[1]).slice(-2);

					return value;
				};

				bindDatePicker();
			});
		},

		//конец календаря

		fillVueFormErrors: function (data, errors) {

		},

		selectClearElement: function (el) {
			el.innerHTML = el.A("placeholder");
		},

		selectClearElementWithVue: function (el, vueObj, vueDataKey) {
			this.selectClearElement(el);
			let obj = vueObj;
			let keyPath = vueDataKey.split('.');
			let len = keyPath.length - 1;
			for (let i = 0; i < len; i++) {
				obj = obj[keyPath[i]];
			}
			obj[keyPath[len]] = null;
		},

		selectReloadElement: function (el, data) {
			let ref = el.previousElementSibling;
			// console.log("REF", ref, ref.A("selfref"));
			if (data) {
				let url = ref.A("selfref");
				if (typeof data === "object") {
					for (let key in data) {
						if (!data.hasOwnProperty(key)) continue;
						url = url.setURLParam(key, data[key]);
					}
				} else {
					url = url.setURLParam("id", data);
				}
				ref.setA("selfref", url);
			}
			// return App.func.reloadEl(ref);
		},

		onChooseWithVue: function (element, def, vueObj, vueDataKey) {
			// console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!`!!!!!!', element, element.previousSibling.previousSibling.previousSibling, def);
			element.previousSibling.previousSibling.innerHTML = def.text;
			element.previousSibling.previousSibling.previousSibling.value = def.rid;

			vueObj[vueDataKey] = def.rid;
		},

		reloadElementWithData: function (el, data) {
		  // console.log("reloadElementWithData", el, data, typeof el);
			if (typeof el === "string") {
				el = I(el);
			}
			if (typeof data !== undefined) {
				let url = el.A("ref-src");
				if (typeof data === "object") {
					for (let key in data) {
						if (!data.hasOwnProperty(key)) continue;
						url = url.setURLParam(key, data[key]);
					}
				} else {
					url = url.setURLParam("id", data);
				}
				el.setA("ref-src", url);
			}
			return App.func.reloadEl(el);
		},

		reloadElementDelay: function (el, delay = 500) {
			return new Promise((resolve,reject) => {
				setTimeout(() => {
					App.func.reloadEl(el, false)
							.then(data => {
								resolve(data);
							})
							.fail(reject);
				}, delay);
			});
		},

		updateDateWithCurrTimeZone: function (el, value, format = 'DD.MM.YYYY HH:mm', def = '-') {
			if (typeof value === 'string') value = value.trim();
			// console.log('updateDateWithCurrTimeZone', value, !!value, typeof value);
			if (value) {
				try {
					let date = moment(value);
					el.innerHTML = date.format(format);
				} catch (e) {}
			} else {
				el.innerHTML = def;
			}
		},

		updateAllDateWithTimeZone: function (el = document) {
			$('.date-with-timezone', el).each(function(index,el) {
				App.func.updateDateWithCurrTimeZone(el, el.getAttribute('tz-date') || el.innerHTML, el.getAttribute('tz-format') || undefined);
			});
		},
		get_cookie: function ( cookie_name ){
			var results = document.cookie.match ( '(^|;) ?' + cookie_name + '=([^;]*)(;|$)' );
			//console.log(decodeURI ( results[2] ));
			if ( results )
				return ( unescape ( results[2] ) );
			else
				return null;
		},
        set_cookie : function (name, value, options) {
            options = options || {};

            var expires = options.expires;

            if (typeof expires == "number" && expires) {
                var d = new Date();
                d.setTime(d.getTime() + expires * 1000);
                expires = options.expires = d;
            }
            if (expires && expires.toUTCString) {
                options.expires = expires.toUTCString();
            }

            value = encodeURIComponent(value);

            var updatedCookie = name + "=" + value;

            for (var propName in options) {
                updatedCookie += "; " + propName;
                var propValue = options[propName];
                if (propValue !== true) {
                    updatedCookie += "=" + propValue;
                }
            }
            document.cookie = updatedCookie;
        },
        delete_cookie:function  (name) {
            App.func.set_cookie(name, "", {
                expires: -1,path:'/'
            })
        },
	},

	handlers: {
		httpError: function (beforeFn, afterFn) {
			return function (err) {
				//console.error(err);
				if (!afterFn) {
					afterFn = beforeFn;
					beforeFn = null;
				}

				beforeFn && beforeFn();
				toastErr.show(err)
						.then(() => {
							afterFn && afterFn();
						});
			}
		},
	}

};

window.App = App;